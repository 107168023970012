import React from 'react';
import { array } from 'prop-types';
import InvoicePayment from './InvoicePayment';

const propTypes = {
  payments: array,
};

const defaultProps = {
  payments: [],
};

const PaymentList = ({ payments }) => {
  if (payments && payments.length > 0) {
    return (
      <div className="payments__list">
        {payments.map(payment => (
          <InvoicePayment
            key={payment.id}
            amount={payment.amount}
            status={payment.payment_status}
            currency={payment.amount_currency}
            createdAt={payment.created_at}
          />
        ))}
      </div>
    );
  }
  return (
    <div className="payments__waiting">
      Awaiting a payment...
    </div>
  );
};

PaymentList.propTypes = propTypes;
PaymentList.defaultProps = defaultProps;

export default PaymentList;
