import React from 'react';
import { I18nProvider } from '@lingui/react';
import { string } from 'prop-types';

import en from 'locales/en/messages';
import fr from 'locales/fr/messages';
import { PusherProvider } from '@harelpls/use-pusher';
import AppRouter from './AppRouter';

import 'tc-biq-design-system/build/styles.css';
import './App.scss';
import { pusherConfig } from '../../../settings';

const catalogs = {
  en,
  fr,
};

const propTypes = {
  language: string.isRequired,
};

const App = ({ language }) => (
  <I18nProvider language={language} catalogs={catalogs}>
    <PusherProvider {...pusherConfig}>
      <AppRouter />
    </PusherProvider>
  </I18nProvider>
);

App.propTypes = propTypes;

export default App;
