import React from 'react';
import {
  BrowserRouter, Route, Switch,
} from 'react-router-dom';

import LoginPage from 'Auth/pages/Login';
import Main from 'App/pages/Main';
import NotFoundPage from 'App/pages/NotFound';
import ProtectedRoute from 'App/components/ProtectedRoute';

const AppRouter = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/login" component={LoginPage} />
      <ProtectedRoute isAllowed path="/" component={Main} />
      <Route component={NotFoundPage} />
    </Switch>
  </BrowserRouter>
);

export default AppRouter;
