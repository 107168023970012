import { object } from 'prop-types';
import { toDataURL } from 'qrcode/build/qrcode.min';
import React, { useEffect, useState } from 'react';
import settings from '../../../settings';
import '../../../style.scss';
import LoadScreen from '../../components/LoadScreen';
import Copy from '../../components/Copy';

const propTypes = {
  match: object.isRequired,
};

const Channel = ({ match: { params: { id: channelId } } }) => {
  const [channel, setChannel] = useState(null);
  const [rates, setRates] = useState({ bid: null, ask: null });
  const [qrCode, setQRCode] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const setBitcoinUrl = (address, amount) => {
    let bitcoinUrl = `bitcoin:${address}`;
    if (amount) {
      bitcoinUrl += `?amount=${amount}`;
    }

    toDataURL(bitcoinUrl, { width: 160, margin: 0 }).then(data => setQRCode(data));
  };

  useEffect(() => {
    const channelUrl = `${settings.API_URL}/api/v1/address/${channelId}`;
    fetch(channelUrl).then(channelRes => channelRes.json().then((data) => {
      if (channelRes.status < 200 || channelRes.status >= 300) {
        throw new Error({ ...data, status: channelRes.status });
      }

      setChannel(data);
      setBitcoinUrl(data.deposit_address, null);

      const base = data.deposit_currency;
      const quote = data.display_amount_currency === 'XYZ' ? data.exchange_to_currency : data.display_amount_currency;
      const ratesUrl = `${settings.API_URL}/backoffice/api/rate/?base=${base}&quote=${quote}`;
      fetch(ratesUrl).then(ratesRes => ratesRes.json().then((ratesData) => {
        if (ratesRes.status < 200 || ratesRes.status >= 300) {
          throw new Error({ ...ratesData, status: ratesRes.status });
        }

        setRates(ratesData);
        setBitcoinUrl(data.deposit_address, null);
        setLoaded(true);
      }));
    }));
  }, [channelId]);

  if (loaded) {
    return (
      <div className="column">
        <div className="card row">
          <img src={qrCode} alt={channel.deposit_address} />
          <div style={{ paddingLeft: '24px', margin: 'auto auto auto 0' }} className="column">
            <div className="label-text" style={{ paddingBottom: '10px' }}>Scan this address and send your funds to:</div>
            <div className="heavy-text">
              {channel.deposit_address}
            </div>
          </div>
        </div>
        <div className="footer" style={{ display: 'flex' }}>
          <div style={{ margin: 'auto auto auto 0', width: '50%' }}>
            <span>
              Exchange rate
            </span>
            {' '}
            <span style={{ fontWeight: 'bold' }}>
              1
              {' '}
              {channel.deposit_currency}
              {' '}
              =
              {' '}
              {rates.bid}
              {' '}
              {channel.display_amount_currency === 'XYZ' ? channel.exchange_to_currency : channel.display_amount_currency}
            </span>
          </div>
          <span style={{ float: 'right', fontSize: '12px', width: '232px' }}>
            Please keep in mind that BTC exchange rate is constantly changing,
            hence the final payment amount may change.
          </span>
        </div>
      </div>
    );
  }
  return <LoadScreen />;
};

Channel.propTypes = propTypes;
export default Channel;
