import React from 'react';
import './style.scss';

const LoadScreen = () => (
  <div className="load-screen" id="loader">
    <svg viewBox="0 0 48 48" className="spinner">
      <path
        className="circle"
        d="M24.2,47.5 C11.1,47.5 0.5,37 0.5,24 C0.5,11 11.1,0.5 24.2,0.5 C37.3,0.5 47.9,11 47.9,24 C47.9,37 37.3,47.5 24.2,47.5 Z M24.2,3.5 C12.8,3.5 3.5,12.7 3.5,24 C3.5,35.3 12.8,44.5 24.2,44.5 C35.6,44.5 44.9,35.3 44.9,24 C44.9,12.7 35.6,3.5 24.2,3.5 Z"
      />
      <path
        className="spinner-circle"
        d="M24.2,47.5 C11.1,47.5 0.5,37 0.5,24 C0.5,23.2 1.2,22.5 2,22.5 C2.8,22.5 3.5,23.2 3.5,24 C3.5,35.3 12.8,44.5 24.2,44.5 C25,44.5 25.7,45.2 25.7,46 C25.7,46.8 25,47.5 24.2,47.5 Z"
      />
    </svg>
  </div>
);

export default LoadScreen;
