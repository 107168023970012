const settings = {
  API_URL: process.env.API_URL,
};

export const pusherConfig = {
  clientKey: process.env.PUSHER_KEY,
  cluster: 'eu',
  forceTLS: true,
};

export default settings;
