import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import InvoicePage from 'App/pages/Invoice';
import ChannelPage from 'App/pages/Channel';

const MainRouter = () => (
  <Fragment>
    <Route path="/invoice/:id" exact component={InvoicePage} />
    <Route path="/address/:id" exact component={ChannelPage} />
  </Fragment>
);

export default MainRouter;
