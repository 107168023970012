import React, { useState, useCallback } from 'react';
import { t, Trans } from '@lingui/macro';
import { func, object } from 'prop-types';
import {
  Row,
  Col,
  Input,
  Button,
  Space,
} from 'tc-biq-design-system';

import './Login.scss';

const propTypes = {
  setLanguage: func.isRequired,
  i18n: object.isRequired,
};

const Login = ({ setLanguage, i18n }) => {
  const [username, setUsername] = useState('');
  const onLanguageChange = useCallback(() => setLanguage('fr'), []);
  const onUsernameChange = useCallback(e => setUsername(e.target.value), []);

  return (
    <Row style={{ justifyContent: 'center' }}>
      <Col sm="50%" lg="20%">
        <Space size={50} />
        <Input label={i18n._(t`Username`)} value={username} onChange={onUsernameChange} />
        <Button><Trans>Login</Trans></Button>
        <Button onClick={onLanguageChange}><Trans>Change Language</Trans></Button>
      </Col>
    </Row>
  );
};

Login.propTypes = propTypes;

export default Login;
