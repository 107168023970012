import React from 'react';
import { Icon } from 'tc-biq-design-system';
import { string } from 'prop-types';

const propTypes = {
  value: string.isRequired,
};

const Copy = ({ value }) => {
  const copyToClipboard = () => {
    const data = new Blob([value], { type: 'text/plain' });
    /* global ClipboardItem */
    const item = new ClipboardItem({ 'text/plain': data });

    navigator.clipboard.write([item]);
  };

  return (
    <button type="button" href="#" className="copy copy__button" onClick={copyToClipboard}>
      <Icon name="Duplicate" />
      Copy
    </button>
  );
};

Copy.propTypes = propTypes;
export default Copy;
