import { connect } from 'react-redux';
import { withI18n } from '@lingui/react';

import { actions as translateActions } from 'App/redux/translate';
import Login from './Login';

const mapDispatchToProps = {
  setLanguage: translateActions.setLanguage,
};

export default withI18n()(
  connect(
    null,
    mapDispatchToProps,
  )(Login),
);
