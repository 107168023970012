import React from 'react';
import { string } from 'prop-types';
import { transactionStatusTextMap, statusColorMap } from '../../../constants';

const propTypes = {
  createdAt: string.isRequired,
  amount: string.isRequired,
  status: string.isRequired,
  currency: string.isRequired,
};

const InvoicePayment = ({ createdAt, amount, status, currency }) => (
  <div style={{ fontSize: '14px', paddingBottom: '5px' }}>
    <span style={{ marginRight: '24px' }}>{createdAt}</span>
    <span style={{ fontWeight: 'bold', marginRight: '24px' }}>{`${amount} ${currency} `}</span>
    <svg height="8" width="8" style={{ marginRight: '8px' }}>
      <circle cx="4" cy="4" r="4" fill={statusColorMap[status]} />
    </svg>
    <span>{transactionStatusTextMap[status] ? transactionStatusTextMap[status] : status}</span>
  </div>
);

InvoicePayment.propTypes = propTypes;
export default InvoicePayment;
