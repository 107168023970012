export const pillTypeMap = {
  COMPLETED: 'status01',
  PENDING: 'status02',
  UNDERPAID: 'status04',
  OVERPAID: 'primary',
  PAID_LATE: 'status03',
  executed: 'status01',
};

export const pillTextMap = {
  COMPLETED: 'Completed',
  PENDING: 'Pending',
  UNDERPAID: 'Underpaid',
};

export const transactionStatusTextMap = {
  executed: 'Transaction confirmed',
  pending: 'Transaction pending',
};

export const colorMap = {
  primary: '#006be6',
  status01: '#1FCB79',
  status02: '#f5ce31',
  status03: '#f29007',
  status04: '#e62222',
};

export const statusColorMap = {
  COMPLETED: colorMap.status01,
  PENDING: colorMap.status02,
  UNDERPAID: colorMap.status04,
  OVERPAID: colorMap.primary,
  PAID_LATE: colorMap.status03,
  executed: colorMap.status01,
  pending: colorMap.status02,
};
