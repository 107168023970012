import React from 'react';

import MainRouter from './MainRouter';

import './Main.scss';

const MainLayout = () => (
  <div className="main-page">
    <div className="main-page__content">
      <MainRouter />
    </div>
  </div>
);

export default MainLayout;
