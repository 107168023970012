import { useChannel, useEvent } from '@harelpls/use-pusher';
import { Decimal } from 'decimal.js';
import { toDataURL } from 'qrcode/build/qrcode.min';
import React, { useEffect, useState } from 'react';
import { Pill } from 'tc-biq-design-system';
import { object } from 'prop-types';
import { pillTypeMap } from '../../../constants';
import settings from '../../../settings';
import '../../../style.scss';
import LoadScreen from '../../components/LoadScreen';
import PaymentList from './PaymentList';
import Copy from '../../components/Copy';

const isCompleted = status => status === 'COMPLETED';

const propTypes = {
  match: object.isRequired,
};

const Invoice = ({ match: { params: { id: invoiceId } } }) => {
  const [invoice, setInvoice] = useState(null);
  const [rates, setRates] = useState({ bid: null, ask: null });
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [timerDone, setTimerDone] = useState(false);
  const [qrCode, setQRCode] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [expiresIn, setExpiresIn] = useState('--:--');

  const setBitcoinUrl = (address, amount) => {
    let bitcoinUrl = `bitcoin:${address}`;
    if (amount) {
      bitcoinUrl += `?amount=${amount}`;
    }

    toDataURL(bitcoinUrl, { width: 160, margin: 0 }).then(data => setQRCode(data));
  };

  const calculateRemainingAmount = (payments, payAmount) => {
    const totalPaid = payments.reduce(
      (acc, payment) => ((new Decimal(payment.amount)).add(acc)),
      new Decimal(0),
    );
    const remaining = Decimal.max(0, new Decimal(payAmount).minus(totalPaid));
    setRemainingAmount(remaining);

    return remaining;
  };

  const createTimer = (expiresAtString, setTimer) => {
    // Update the count down every 1 second
    const x = setInterval(() => {
      // Get todays date and time
      const now = Date.now();
      const expiresAt = Date.parse(expiresAtString);

      // Find the distance between now an the count down date
      const distance = expiresAt - now;

      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString();
      let seconds = Math.floor((distance % (1000 * 60)) / 1000).toString();

      // Display the result in the element with id="demo"
      // If the count down is finished, write some text
      if (distance < 0 && !timerDone) {
        clearInterval(x);
        minutes = '00';
        seconds = '00';
      }
      minutes = (`0${minutes}`).slice(-2);
      seconds = (`0${seconds}`).slice(-2);
      setTimer(`${minutes}:${seconds}`);
    }, 1000);
  };

  useEffect(() => {
    const invoiceUrl = `${settings.API_URL}/api/v1/invoice/${invoiceId}`;
    fetch(invoiceUrl).then(invoiceRes => invoiceRes.json().then((data) => {
      if (invoiceRes.status < 200 || invoiceRes.status >= 300) {
        throw new Error({ ...data, status: invoiceRes.status });
      }

      setInvoice(data);
      setBitcoinUrl(data.address, null);
      setTimerDone(isCompleted(data.status));
      createTimer(data.guarantee_expires_at, setExpiresIn);

      const remaining = calculateRemainingAmount(data.payments, data.pay_amount);
      setBitcoinUrl(data.address, remaining);
      setRates({ bid: data.original_exchange_rate, ask: null });

      setLoaded(true);
    }));
  }, [invoiceId]);

  useEffect(() => {
    if (invoice && ['PAID_LATE', 'UNDERPAID'].includes(invoice.status)) {
      const remaining = calculateRemainingAmount(invoice.payments, invoice.pay_amount);

      const base = invoice.pay_currency;
      const quote = invoice.currency;
      const ratesUrl = `${settings.API_URL}/backoffice/api/rate/?base=${base}&quote=${quote}`;
      fetch(ratesUrl).then(ratesRes => ratesRes.json().then((ratesData) => {
        if (ratesRes.status < 200 || ratesRes.status >= 300) {
          throw new Error({ ...ratesData, status: ratesRes.status });
        }

        setRates(ratesData);
        setBitcoinUrl(invoice.address, remaining);
      }));
    } else if (invoice) {
      setRates({ bid: invoice.original_exchange_rate, ask: null });
    }
  }, [invoice]);

  const paymentCreatedHandler = (data) => {
    Decimal.set({ precision: 22, rounding: 8 });
    const paymentAmount = new Decimal(data.amount);
    const remaining = Decimal.max(
      (new Decimal(remainingAmount)).sub(paymentAmount),
      new Decimal(0),
    );

    setInvoice({
      ...invoice,
      payments: [...invoice.payments, data],
    });

    setRemainingAmount(remaining);
    setBitcoinUrl(invoice.address, remaining);
  };

  const paymentConfirmedHandler = (data) => {
    const payments = invoice.payments.map((payment) => {
      if (payment.id === data.id) {
        return data;
      }
      return payment;
    });

    setInvoice({
      ...invoice,
      payments,
    });
  };

  const updateInvoiceStatus = (data) => {
    setInvoice({
      ...data,
    });

    setTimerDone(isCompleted(data.status));
  };

  const channel = useChannel(invoiceId);
  useEvent(channel, 'invoice.payment_created', paymentCreatedHandler);
  useEvent(channel, 'invoice.payment_confirmed', paymentConfirmedHandler);

  useEvent(channel, 'invoice.paid', updateInvoiceStatus);
  useEvent(channel, 'invoice.completed', updateInvoiceStatus);
  useEvent(channel, 'invoice.refunded', updateInvoiceStatus);
  useEvent(channel, 'invoice.overage_refunded', updateInvoiceStatus);
  useEvent(channel, 'invoice.overage_exchanged', updateInvoiceStatus);
  useEvent(channel, 'invoice.underpaid', updateInvoiceStatus);
  useEvent(channel, 'invoice.overpaid', updateInvoiceStatus);
  useEvent(channel, 'invoice.paid_late', updateInvoiceStatus);
  useEvent(channel, 'invoice.cancelled', updateInvoiceStatus);

  // -------- Rendering
  if (loaded) {
    return (
      <div className="column">
        <div className="card row">
          <img src={qrCode} alt={invoice.address} />
          <table>
            <tbody>
              <tr>
                <td>Expires at</td>
                <td><Pill iconPosition="left" icon="Clock" type="neutral">{expiresIn}</Pill></td>
              </tr>
              <tr>
                <td>Amount</td>
                <td>{`${invoice.amount} ${invoice.currency}`}</td>
              </tr>
              <tr>
                <td>Send exact amount</td>
                <td>
                  {`${remainingAmount ? remainingAmount.toFixed(8) : '...'} BTC`}
                </td>
              </tr>
              <tr>
                <td>To this BTC address</td>
                <td>
                  {invoice.address}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="payments">
          <div className="payments__status">
            <span style={{ marginRight: '16px' }}>Status</span>
            <Pill type={pillTypeMap[invoice.status]}>{invoice.status.replace('_', ' ')}</Pill>
          </div>
          <PaymentList payments={invoice.payments} />
        </div>
        <div className="footer">
          Exchange rate
          {' '}
          <span style={{ fontWeight: 'bold' }}>
            1
            {' '}
            {invoice.pay_currency}
            {' '}
            =
            {' '}
            {rates.bid}
            {' '}
            {invoice.currency}
          </span>
        </div>
      </div>
    );
  }
  return <LoadScreen />;
};

Invoice.propTypes = propTypes;
export default Invoice;
